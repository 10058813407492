<template>
  <span>
    <template v-for="(item) in type" >
      <!-- {{ item.label }} -->
      <span v-if="item.value == value" > {{ item.label}}</span>  
    </template>
  </span>
</template>

<script>
import {getDict} from "@/api/enjoy/enjoy";
export default {
  name: "DictTag",
  props: ['type','value'] ,
  watch: {
    type(o,n){
      // console.log(o,n);
    },
  }

  // props: {
  //   type: String,
  //   value: [Number, String, Boolean, Array],
  // },
};
</script>
<style scoped>
.el-tag + .el-tag {
  margin-left: 10px;
}
</style>
