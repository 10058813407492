<template>
  <div class="container" v-if="detail" >
    <breadcrumb :current="{ title: detail.title }"></breadcrumb>
    <main-info :title="detail.title">
      <img slot="img" :src="detail.titleImg" alt="">
      <div slot="content">
        <p>项目编号:{{ detail.projectCode }}</p>
        <p>类别: <dict-tag v-if="dict.category" :type="dict.category" :value="detail.category" /></p>
        <p>级别:<dict-tag v-if="dict.level"  :type="dict.level" :value="detail.level" /></p>
        <p>批次:<dict-tag v-if="dict.batch" :type="dict.batch" :value="detail.batch" /></p>
        <p>公示时间:{{ formatDate(detail.createTime, 'yyyy年MM月dd日') }}</p>
        <p>区域:{{ detail.area }}</p>
      </div>
    </main-info>
    <!-- 选项卡 -->
    <category isDetail @change="changeCategory" :categorys="categorys"></category>
    <!-- project -->
    <txt-view v-if="categoryIndex === 0" class="txt" :txt="detail.txt"></txt-view>
    <comment v-else :id="$route.params.id" :contentType="$route.meta.contentType" />
    <recommend url="project" :id="$route.params.id"  :contentType="$route.meta.contentType" />
  </div>
</template>

<script>
import { getDetail,getDict} from "@/api/enjoy/enjoy";
// 主信息插槽
import MainInfo from "@/components/MainInfo";
import DictTag from '@/components/DictTag'
import Recommend from '@/components/RelatedRecommend'
  export default {
    
    name:'projectDetail',
    components: {
      MainInfo,DictTag,Recommend
    },

    watch: {
     $route(){
      this.getData()

     } 
    },
    data() {
      return {
        detail:null,
        dict:{
          dict:[],
          category:[],
          level:[]
        },  
        categoryIndex: 0,//分类下标
        categorys: [{
                name: '非遗详情',
                newsType: 1
            }, {
                name: '评论',
                newsType: 2
            }],
      }
    },
    created () {
      this.getDictList()

      this.getData()
    },
    
    methods: {
      changeCategory(index) {
            this.categoryIndex = index
            // this.getList()
        },
      getData() {
        getDetail('/ich/project/get',{id: this.$route.params.id}).then(res=>{
          this.detail=res.data
        })
      },
      getDictList() {
            getDict('ich_inheritor_batch').then(res => { this.dict.batch = res.data })
            getDict('ich_inheritor_category').then(res => { this.dict.category = res.data })
            getDict('ich_inheritor_level').then(res => { this.dict.level = res.data })
        },
    },

  }
</script>

<style lang="scss" scoped>
.container {
  width: 1200px;
    margin: 0 auto;
}
</style>