import request from '@/utils/request'

export function getData(type=1,query) {
    return request({
        url: type==1?'/ich/project/page':'/ich/inheritor/page',
        method: 'get',
        params: query
    })
}
export function getDict(url){
  return request({
    url:'/system/dict-data/'+url,
    method: 'get',
})
}
// 获取详情
export function getDetail(url,query){
    return request({
      url,
      method: 'get',
      params: query
  })
  }


// // 获得直播分页
// export function getLivePage(query) {
//     return request({
//         url: '/ich/live/page',
//         method: 'get',
//         params: query
//     })
// }